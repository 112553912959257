import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { Container } from 'ui/containers';
import backgroundProfil from '../assets/images/backgroundProfil.jpg';
import { getGeneralInformations } from 'api/general';
import Content from 'components/Content';
import { useAsync } from 'react-async';

const Picture = styled.div`
  background-image: url("${backgroundProfil}");
  background-size: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const PoliciesStyle = createGlobalStyle`
  h4 {
    text-align:center;
    font-size: 20px;
    font-weight: 400;
  }

  h5 {
    color: #73c992;
    font-size:18px
  }
`;

const Profil = () => {
  const { t } = useTranslation();

  const { data: { generalInformation } = {} } = useAsync({
    promiseFn: getGeneralInformations,
  });

  const { privacyPolicy } = generalInformation || {};

  return (
    <Layout>
      <SEO title={t('pageTitle.privacy')} />
      <Picture />

      <Container>
        <PoliciesStyle />
        {privacyPolicy && <Content html={privacyPolicy} />}
      </Container>
    </Layout>
  );
};

export default Profil;
